import getData from "@/__main__/get-data.mjs";
import CS2MapLineups from "@/data-models/cs2-map-lineups.mjs";
import { LINEUPS_MAPS } from "@/game-cs2-marketing/constants.mjs";
import * as API from "@/game-csgo/api.mjs";

function fetchData(_params, searchParams) {
  const map = searchParams.get("map") || "all";
  const promises = [];
  if (map === "all") {
    for (const key of LINEUPS_MAPS) {
      promises.push(
        getData(API.getLineups(key), CS2MapLineups(key), [
          "cs2",
          "lineups",
          key,
        ]),
      );
    }
  } else {
    promises.push(
      getData(API.getLineups(map), CS2MapLineups(map), ["cs2", "lineups", map]),
    );
  }
  return Promise.all(promises);
}

export default fetchData;
