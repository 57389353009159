import createModel from "@/__main__/data-model.mjs";

const model = [
  {
    id: String,
    title: String,
    description: String,
    grenade: String,
    side: String,
    coordinates: [Number],
    playerCoords: [Number],
    action: String,
    difficulty: String,
    setpos: String,
    video: {
      mp4: String,
    },
    active: Boolean,
  },
];

const afterTransformModel = [
  {
    ...model[0],
    map: String,
  },
];

const getValidationFn = (key) =>
  createModel(model).transform(afterTransformModel, (data) => {
    return data.filter((d) => d.video.mp4).map((d) => ({ ...d, map: key }));
  });

export default getValidationFn;
